import React, { Component } from 'react';
import {
  HashRouter,
  BrowserRouter as Routing,
  Routes,
  Route,
} from 'react-router-dom';

import Index from './pages/';
import Thanyou from './pages/thanyou';
import GetAll from './pages/getAll';
function Router() {
  return (
    <Routing basename={'/'}>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/thankyou" element={<Thanyou />} />
        <Route path="/members" element={<GetAll />} />
      </Routes>
    </Routing>
  );
}
export default Router;
