import React from 'react';
const Thank = () => {
  return (
    <>
      <div className={' mx-auto d-block'}>
        <div className={' text-center py-4 py-md-5'}>
          <h1 className={'mb-2 text-success text-bold'}>{'Merci!'}</h1>
          <p className={' h5  fst-italic text-black text-regular'}>
            {
              'Votre inscription a bien été enregistrée. RDV le 21 Octobre 2023 à FOMOPEA'
            }
          </p>
          <p className={' h5  fst-italic text-black text-regular'}>
            {
              "Pour toute information, bien vouloir contacter le Président du Comité d'Organisation  du Forum au 696 80 46 71 ou le secrétaire Général de l’ADGF 671 44 31 17"
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default Thank;
