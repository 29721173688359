import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import dateFormat from 'dateformat';
import _ from 'lodash';
//api
import { getAll } from '../api/getAll';

const GetAll = () => {
  //state to manage list of registered members
  const [members, setMembers] = useState([]);
  /* Effects */
  useEffect(() => {
    getAll()
      .then((res) => {
        // if submit done go to the thank you page success
        if (_.isEqual(res.status, 200)) {
          setMembers(res.data);
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <div className="container pb-5 mb-5 bg-white  rounded border ">
        <div className="h2 py-3  text-center col-12">
          {`Liste des ${members.length} membres inscrits`}
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th>Date d'inscription</Th>
              <Th>Genre</Th>
              <Th>Nom</Th>
              <Th>Prénom</Th>
              <Th>Ville de résidence</Th>
              <Th>Année de naissance</Th>
              <Th>Profession</Th>
              <Th>Téléphone</Th>
              <Th>Email</Th>
            </Tr>
          </Thead>
          <Tbody>
            {members &&
              members.map((item, key) => (
                <Tr key={key} value={item.id}>
                  <Td>
                    {dateFormat(item.createdAt, 'ddd mmm dd yyyy HH:MM:ss')}
                  </Td>
                  {item.gender === 'Mr' ? (
                    <Td>{'Homme'}</Td>
                  ) : (
                    <Td>{'Femme'}</Td>
                  )}
                  <Td>{item.lastname}</Td>
                  <Td>{item.firstname}</Td>
                  <Td>{item.city}</Td>
                  <Td>{item.yearofbirth}</Td>
                  <Td>{item.occupation}</Td>
                  <Td>{item.phonenumber}</Td>

                  {item.email === '' ? (
                    <Td>{'Non renseigné'}</Td>
                  ) : (
                    <Td>{item.email}</Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </div>
    </>
  );
};

export default GetAll;
