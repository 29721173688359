import React from 'react';
import GetAll from '../components/getAll';
import Header from '../components/header';
import '../assets/css/index.css';

function Get() {
  return (
    <div className="main">
      <Header />
      <GetAll />
    </div>
  );
}

export default Get;
