import React, { useState } from 'react';
//import { Button, Checkbox, Form } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { useFormik } from 'formik';
import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Header from './header';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';

//api
import { createForm } from '../api/createForm';

const Create = () => {
  let navigate = useNavigate();

  //state to manage date of birth after submitting
  const [requestError, setRequestError] = useState(false);
  //state to manage date of birth after submitting
  const [isYear, setIsYear] = useState(false);
  //state to manage loading
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  //state to manage yar of birth
  const [birthyear, setBirthyear] = useState(dayjs(new Date('2002-01-01')));

  // Handle The year of birth change
  const handleDateChange = (e) => {
    if (birthyear !== undefined && birthyear !== null) setBirthyear(e);
  };

  //submit form
  const _handleFormSubmit = (values) => {
    setBtnIsLoading(true);
    // prepare payload and rename
    let payload = {
      ...values,
    };

    createForm(payload)
      .then((res) => {
        setBtnIsLoading(false);
        // if submit done go to the thank you page success
        if (_.isEqual(res.status, 200)) {
          navigate('/thankyou');
        }
      })
      .catch(() => {
        // if submit don't done go to the thank you page error
        setBtnIsLoading(false);
        setRequestError(true);
      });
  };

  // Method ; verifies variable validity
  const _isValid = (param) => {
    if (!param) {
      return false;
    }
    // test regex
    return /^\+?(0|[1-9]\d*)$/.test(param);
  };

  // Method ; verifies email validity
  const _isEmailValid = (email) => {
    // test regex
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  };
  const FORM_INIT = {
    lastname: '',
    firstname: '',
    gender: `Mr`,
    city: '',
    yearofbirth: '',
    occupation: '',
    phonenumber: '',
    email: '',
  };
  const [formInit, setFormInit] = useState(FORM_INIT);
  //validate form
  const _validateForm = (values) => {
    // errors
    const errors = {};
    // first name
    if (!values.firstname) {
      errors.firstname = 'le prénom est obligatoire';
    }
    // last name
    if (!values.lastname) {
      errors.lastname = 'le nom est obligatoire';
    }

    // City
    if (!values.city) {
      errors.city = 'le nom de votre ville de résidence est obligatoire';
    }

    // Year of birth
    if (!values.yearofbirth) {
      errors.yearofbirth = 'Votre année de Naissance est obligatoire';
    } else if (!_isValid(values.yearofbirth)) {
      errors.yearofbirth = 'Saisissez une année de naissance valide';
    } else if (!(values.yearofbirth >= 1943)) {
      errors.yearofbirth = 'Vous ne pouvez pas avoir plus de 80 ans ';
    } else if (!(2008 >= values.yearofbirth)) {
      errors.yearofbirth = 'Vous ne pouvez pas avoir moins  de 15 ans ';
    }

    // phonenumber
    if (!values.phonenumber) {
      errors.phonenumber = 'Votre numéro de téléphone est obligatoire';
    } else if (!_isValid(values.phonenumber)) {
      errors.phonenumber = 'Saisissez un numéro de téléphone valide';
    }
    // phonenumber
    if (!values.gender) {
      errors.gender = 'Votre numéro de téléphone est obligatoire';
    }

    // email
    if (values.email) {
      if (!_isEmailValid(values.email)) {
        errors.email = 'Veuillez saisir une adresse mail valide';
      }
    }
    // return
    return errors;
  };

  const {
    dirty,
    isValid,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    validateForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formInit,
    validate: _validateForm,
    onSubmit: _handleFormSubmit,
  });

  return (
    <>
      <div className="container bg-white d-flex   col-12 col-md-6 rounded ">
        <form onSubmit={handleSubmit} id={`create-id`}>
          <div className="h2 py-3 ms-5"> Fiche d'inscription au Forum</div>
          {/*-------------------------------civility------------------------------------------*/}
          <div className={'row ms-5  col-12 mt-4'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Civilité(*)</div>
            <div className={'col-12 ps-0 d-flex'}>
              <div className={'pe-3 border rounded me-3'}>
                <Radio
                  value={`Mr`}
                  id="radio-Mr"
                  name="gender"
                  color={'primary'}
                  onChange={handleChange}
                  checked={values.gender === `Mr`}
                />
                <span>{`Mr`}</span>
              </div>
              <div className={'pe-3 border rounded'}>
                <Radio
                  value={`Mme`}
                  id="radio-Mme"
                  name="gender"
                  color={'primary'}
                  onChange={handleChange}
                  checked={values.gender === `Mme`}
                />
                <span className={'text-black'}>{`Mme`}</span>
              </div>
            </div>
          </div>
          {/*--------------last-name------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Nom(*)</div>
            <TextField
              className={'w-100'}
              id="last-name"
              name="lastname"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.lastname ? errors.lastname : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.lastname && !_.isEmpty(errors.lastname)}
            />
          </div>
          {/*--------------first-name------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Prénom(*)</div>
            <TextField
              className={'w-100'}
              id="first-name"
              name="firstname"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.firstname ? errors.firstname : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.firstname && !_.isEmpty(errors.firstname)}
            />
          </div>
          {/*--------------city------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>
              Ville de résidence(*)
            </div>
            <TextField
              className={'w-100'}
              id="city"
              name="city"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.city ? errors.city : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.city && !_.isEmpty(errors.city)}
            />
          </div>
          {/*--------------city------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>
              Année de naissance(*)
            </div>
            <TextField
              className={'w-100'}
              id="yearofbirth"
              name="yearofbirth"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.yearofbirth ? errors.yearofbirth : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.yearofbirth && !_.isEmpty(errors.yearofbirth)}
            />
          </div>
          {/*--------------Year of birth------------------------------*/}

          {/*     <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div
              className={`mb-2 ps-0  fs-5 ${
                birthyear === undefined || birthyear === null
                  ? 'text-red'
                  : 'text-black'
              }`}
            >
              Année de Naissance(*)
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  maxDate={dayjs(new Date('2002-01-01'))}
                  defaultValue={dayjs(new Date('2002-01-01'))}
                  className="w-100"
                  views={['year']}
                  label="Choisissez votre année de naissance en cliquant sur le calendrier"
                  value={birthyear}
                  onChange={(e) => handleDateChange(e)}
                  disableFuture
                  autoOk={true}
                  slotProps={{
                    textField: {
                      id: 'year-of-birth',
                      name: 'yearofbirth',
                      variant: 'outlined',
                      error:
                        touched.yearofbirth && !_.isEmpty(errors.yearofbirth),
                      helperText: touched.yearofbirth ? errors.yearofbirth : '',
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
           */}
          {/*----- ---------occupation------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Profession(*)</div>
            <TextField
              className={'w-100'}
              id="occupation"
              name="occupation"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.occupation ? errors.occupation : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.occupation && !_.isEmpty(errors.occupation)}
            />
          </div>
          {/*--------------Phonenumber------------------------------*/}
          <div className={'pt-3 pt-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Téléphone(*)</div>
            <TextField
              className={'w-100'}
              id="phonenumber"
              name="phonenumber"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.phonenumber ? errors.phonenumber : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.phonenumber && !_.isEmpty(errors.phonenumber)}
            />
          </div>
          {/*--------------Email------------------------------*/}
          <div className={' py-3 py-md-5 col-12  ms-5'}>
            <div className={'mb-2 ps-0 text-black  fs-5'}>Adresse Email</div>
            <TextField
              className={'w-100'}
              id="email"
              name="email"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.email ? errors.email : ''}
              placeholder={`Saisissez Votre Réponse ici`}
              error={touched.email && !_.isEmpty(errors.email)}
            />
          </div>
          <div className={' pb-3 pb-md-5 col-12  ms-5'}>
            <Button
              disabled={!isValid}
              color="success"
              type={'submit'}
              variant="contained"
              form={'create-id'}
              className="text-bold w-50 mt-5"
            >
              <span>{`S'inscrire`}</span>
              {btnIsLoading && <CircularProgress />}
            </Button>
            {requestError && (
              <Alert
                onClose={() => {
                  setRequestError(false);
                }}
                severity="error"
              >
                <AlertTitle>Erreur</AlertTitle>
                Une erreur inconnue est survenue lors de l'opération —{' '}
                <strong>
                  Veuillez réessayer ou contacter votre administrateur
                </strong>
              </Alert>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Create;
