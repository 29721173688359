import React from 'react';
import Create from '../components/create';
import Header from '../components/header';
import '../assets/css/index.css';

function CreateMember() {
  return (
    <div className="main">
      <Header />
      <Create />
    </div>
  );
}

export default CreateMember;
