import React from 'react';
import Thank from '../components/thank';
import Header from '../components/header';
import '../assets/css/index.css';

function ThankYou() {
  return (
    <div className="main">
      <Header />
      <Thank />
    </div>
  );
}

export default ThankYou;
