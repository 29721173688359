import logo from './logo.svg';
import './App.css';
import Router from './router';
import 'semantic-ui-css/semantic.min.css';
import Create from './components/create';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  return <Router />;
}

export default App;
