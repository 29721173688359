import React, { useState } from 'react';
import Logo from '../assets/images/logo_adgf.png';

const Header = () => {
  return (
    <>
      <img className={'mx-auto d-block'} src={Logo} alt="img-1" width={200} />
      <div className={' header-title my-3 '}>
        <div className={'container text-white text-center py-4 py-md-5'}>
          <h2 className={'mb-2 text-bold'}>
            {'Forum Agropastoral Du 21 Octobre 2023'}
          </h2>
          <h4 className={' fst-italic text-regular'}>
            {'Association pour le Développement Du Groupement Fomopea(A.D.G.F)'}
          </h4>
        </div>
      </div>
    </>
  );
};

export default Header;
